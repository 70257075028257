import React, { useEffect } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import OrderConfirmationPageModel from './Models/OrderConfirmationPageModel.interface';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import OrderSummary from './../CheckoutPage/OrderSummary';
import { SmallButton } from '../Shared/Button/Button';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import Grid from '../Shared/Grid/Grid';
import useMedia from '../Shared/Hooks/useMedia';
import { GridSize } from '../Enums/GridSize.enum';
import Translate from '../Shared/Common/Translate';
import { purchaseDataLayer } from '../utils/dataLayer.lib';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import { GetCart } from '../Cart/Cart';

function OrderConfirmationPage() {
  const {
    order,
    subHeading,
    customerNumber,
    company,
    promotions,
  } = useCurrentPage<OrderConfirmationPageModel>();

  const {
    translations: {
      'orderConfirmationPage/or': orText,
      'orderConfirmationPage/goToOrderHistory': goToOrderText,
      'orderConfirmationPage/goToStartPage': goToStartText,
      'orderConfirmationPage/thankYou': header,
      'checkoutPage/customerNumber': customerNumberText,
      'checkoutPage/companyHeader': companyText,
      'checkoutPage/orderNote': orderNoteText,
      'checkoutPage/deliveryAddress': deliveryAddressText,
      'orderConfirmationPage/orderInfo': orderInfoText,
    },
    siteRoute,
    staticPages: { orderHistoryPage },
  } = useAppSettingsData();
  const kexNavigate = useKexNavigate();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);
  const isTablet = useMedia(theme.mediaQuery.mediaMaxSmall);
  const id = useEncodedCurrentUser();
  const cart = GetCart(id).cart;
  const goToHistoryPage = () => {
    kexNavigate(orderHistoryPage);
  };

  const goToStartPage = () => {
    kexNavigate(`/${siteRoute}`);
  };

  useEffect(() => {
    purchaseDataLayer(order);
  }, []);

  return (
    <Root>
      <Grid
        type={
          isMobile ? GridSize.Two : isTablet ? GridSize.Three : GridSize.Eight
        }
      >
        <BlockContainer>
          <Container>
            <Box>
              <Header>{header}</Header>
              <SubHeader>{subHeading}</SubHeader>
              <TextField>
                <Translate
                  translation={orderInfoText}
                  values={[<styled.Strong>{order.orderNumber}</styled.Strong>]}
                />
              </TextField>
              <InformationLine>
                <styled.Strong> {companyText} </styled.Strong>
                {company}
              </InformationLine>
              <InformationLine>
                <styled.Strong>{customerNumberText}</styled.Strong>
                {customerNumber}
              </InformationLine>
              {order.shippingAddress && (
                <InformationLine>
                  <styled.Strong> {deliveryAddressText} </styled.Strong>
                  {order.shippingAddress.address}
                </InformationLine>
              )}
              <InformationLine>
                <styled.Strong> {orderNoteText} </styled.Strong>
                <br /> {order.orderNote}
              </InformationLine>
            </Box>
            <Box>
              <OrderSummary
                cart={cart}
                numberOfItems={order.numberOfItems}
                totalPriceExclVat={order.totalPriceExclVAT}
                totalFreightPrice={order.totalFreightPrice}
                promotions={promotions}
                totalPrice={order.totalPrice}
                totalPriceForItemsWithFreightPrice={
                  order.totalPriceForItemsWithFreightPrice
                }
              />
            </Box>
          </Container>
          <ButtonContainer>
            <RedirectButton onClick={goToHistoryPage} isActive={true}>
              {goToOrderText}
            </RedirectButton>
            <OrText> {orText}</OrText>
            <RedirectButton onClick={goToStartPage}>
              {goToStartText}
            </RedirectButton>
          </ButtonContainer>
        </BlockContainer>
      </Grid>
    </Root>
  );
}

export default OrderConfirmationPage;

const Root = styled.div({
  color: theme.black,

  lineHeight: theme.lineHeight.snug,
  letterSpacing: theme.letterSpacing.nearMedium,
  paddingBottom: theme.spacing(20),
  ...media(theme.mediaQuery.mediaMinLarge, {
    paddingBottom: theme.spacing(16),
    backgroundColor: theme.thinGray,
    flexGrow: 1,
  }),
});

const BlockContainer = styled.div({
  backgroundColor: theme.white,
  width: '100%',
  margin: {
    top: theme.spacing(6),
    x: 'auto',
  },

  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(8),
    },
    marginTop: theme.spacing(7),

    gridColumnStart: '2',
    gridColumnEnd: '8',
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnStart: '1',
    gridColumnEnd: '5',
  }),
});

const Container = styled.div({
  display: 'grid',
  gridGap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  width: '100%',
  height: 'auto',
  justifyContent: 'center',
  margin: {
    x: 'auto',
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '7fr 3.5fr',
    gridGap: theme.spacing(8),
    marginBottom: theme.spacing(8),
  }),
  maxWidth: theme.contentMaxWidth,
});

const TextField = styled.div({ marginBottom: '1em' });

const InformationLine = styled.p({
  margin: { y: theme.spacing(2) },
  lineBreak: 'anywhere',
});

const OrText = styled.div({
  width: 'fit-content',
  margin: { x: 'auto', y: theme.spacing(4) },
});

const ButtonContainer = styled.div({
  flexWrap: 'wrap',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '100%',
  }),
});

const RedirectButton = styled(SmallButton, {
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.loose,
  width: theme.orderConfirmationButtonWidth,
  margin: { x: 'auto' },
  display: 'block',
  height: 'auto',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '100%',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(32), y: theme.spacing(4) },
  }),
});

const Box = styled.div({
  backgroundColor: theme.white,
});

const Header = styled.h2({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  color: theme.black,
  margin: { bottom: theme.spacing(2) },
  lineHeight: theme.lineHeight.moreRelaxed,
  letterSpacing: theme.letterSpacing.wider,
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.eta }),
});

const SubHeader = styled.h2({
  font: { size: theme.gamma },
  color: theme.black,
  margin: { bottom: theme.spacing(2) },
  lineHeight: theme.lineHeight.moreRelaxed,
  letterSpacing: theme.letterSpacing.wide,
});
